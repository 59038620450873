.GoSiteHero-arrow {
  pointer-events: none;
}
 
.GoSiteHero-arrow .arrow__path {
  fill: transparent;
  stroke: #91B4FA;
  stroke-width: 2px;
  /* stroke-dasharray: 4 2; */
}
 
.GoSiteHero-arrow .arrow__head line {
  stroke: #91B4FA;
  stroke-width: 2px;
}

.GoSiteHero-arrow .arrow__path, .GoSiteHero-arrow .arrow__head {
  fill: none;
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-name: draw;
          animation-name: draw;
}
.GoSiteHero-arrow .arrow__head line:first-of-type {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.GoSiteHero-arrow .arrow__head line + line {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}