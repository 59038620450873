/* 
Avenir® Next
The Beautiful Stranger
https://www.linotype.com/2090/avenir-next.html#:~:text=Avenir%20Next%20is%20part%20of,balance%20of%20harmony%20and%20contrast.
*/

@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/AvenirNextLTPro-UltLt.woff2') format('woff2'),
      url('../fonts/AvenirNextLTPro-UltLt.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/AvenirNextLTPro-Regular.otf') format('opentype'),
       url('../fonts/AvenirNextLTPro-Regular.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/AvenirNextLTPro-Medium.woff2') format('woff2'),
      url('../fonts/AvenirNextLTPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
      url('../fonts/AvenirNextLTPro-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family:'Avenir Next';
  src: url('../fonts/avenir_next.eot') format('eot'),
  url('../fonts/avenir_next.ttf') format('truetype'),
  url('../fonts/avenir_next.woff') format('woff'),
  url('../fonts/avenir_next.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/*
Mont
https://www.fontfabric.com/fonts/mont/
*/

/* @font-face {
  font-family: 'Mont';
  src: url('https://www.gosite.com/hubfs/Branding/Fonts/Mont/Mont-SemiBold.woff2') format('woff2'),
      url('https://www.gosite.com/hubfs/Branding/Fonts/Mont/Mont-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
} */

/* @font-face {
  font-family: 'Mont';
  src: url('https://cdn2.hubspot.net/hubfs/3938013/Gosite%20%20Theme%202018/Fonts/Mont-Bold.woff2') format('woff2'),
      url('https://cdn2.hubspot.net/hubfs/3938013/Gosite%20%20Theme%202018/Fonts/Mont-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
} 

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
} 

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
